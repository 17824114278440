export const GUIDE01 = process.env.PUBLIC_URL + '/assets/guide/guide01.svg';
export const GUIDE02 = process.env.PUBLIC_URL + '/assets/guide/guide02.svg';
export const GUIDE03 = process.env.PUBLIC_URL + '/assets/guide/guide03.svg';
export const GUIDE04 = process.env.PUBLIC_URL + '/assets/guide/guide04.svg';
export const GUIDE05 = process.env.PUBLIC_URL + '/assets/guide/guide05.svg';
export const GUIDE06 = process.env.PUBLIC_URL + '/assets/guide/guide06.svg';
export const GUIDE07 = process.env.PUBLIC_URL + '/assets/guide/guide07.svg';

export const TUTORIAL01 =
  process.env.PUBLIC_URL + '/assets/guide/tutorial01.svg';
export const TUTORIAL02 =
  process.env.PUBLIC_URL + '/assets/guide/tutorial02.svg';
